import React from 'react';
import Swal from 'sweetalert2';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencil, faTrash } from '@fortawesome/free-solid-svg-icons';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { deleteAdminAddress, editAdminAddress, setDefaultNetworkAdminAddress } from '../redux/adminAddress/adminAddressActions';
import { ErrorSpan } from '../components';
import { omitColumn, prettyCutOutputByCellWidth, valueOutputCutLength } from './helper';
import { UsersInUseModal } from './UsersInUseModal/UsersInUseModal';
import { TooltipComponent } from '../components/TooltipComponent/TooltipComponent';

export const AdminAddressPspSchema = ({
  permissionName,
  setIsModalShown,
  setSelectedAddressId,
  columnConfig,
  tempFilters,
  setTempFilters,
  showModalContext,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { currentUserPermissions } = useSelector((state) => state.crmUser);
  const isUserCanEditPspOther = currentUserPermissions && currentUserPermissions.length > 0 && currentUserPermissions.includes('psp_other_edit');
  const isUserCanDeletePspOther = currentUserPermissions && currentUserPermissions.length > 0 && currentUserPermissions.includes('psp_other_delete');

  const showModal = (id) => {
    setIsModalShown(true);
    setSelectedAddressId(id);
  };

  const deleteAction = (id) => {
    Swal.fire({
      title: 'Are you sure you want to Delete?',
      html: '',
      showCloseButton: true,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
    }).then((result) => {
      if (result.isConfirmed === true) {
        dispatch(deleteAdminAddress(id));
      }
    });
  };

  const setDefaultAction = (id, currencyId, network) => {
    Swal.fire({
      title: 'Are you sure you want to set it as default Address for selected Currency?',
      html: '',
      showCloseButton: true,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
    }).then((result) => {
      if (result.isConfirmed === true) {
        const data = {
          isDefault: true,
          currencyId,
        };

        if (network) {
          data.network = network;
        }

        dispatch(setDefaultNetworkAdminAddress(id, data));
      }
    });
  };

  const unsetDefault = (id) => {
    dispatch(editAdminAddress(id, { isDefault: false }));
  };

  const renderInputColumn = (fieldName, placeholder, filterKey) => (
    <div className="d-flex flex-column search-input-des pt-2" style={{ alignItems: 'start' }}>
      {fieldName}
      <input
        type="text"
        name={filterKey}
        autoComplete="off"
        value={tempFilters[filterKey] || ''}
        placeholder={placeholder}
        className="email-templates-search-bar-filter"
        onChange={({ target: { value } }) => {
          setTempFilters({
            ...tempFilters,
            [filterKey]: value,
          });
        }}
      />
      <ErrorSpan
        filter={tempFilters[filterKey] || ''}
        message="Please enter at least 3 characters"
      />
    </div>
  );

  const columns = [
    {
      name: renderInputColumn('Address', 'Filter by address', 'address'),
      cell: ({ address }) => (
        address.length >= valueOutputCutLength.pspWallet
          ? (
            <TooltipComponent
              title={address}
              // eslint-disable-next-line react/no-children-prop
              children={(
                <span style={{ paddingLeft: '12px', cursor: 'pointer' }}>      
                  {prettyCutOutputByCellWidth(address, 'pspWallet')}
                </span>
              )}
            />
          )
          : (
            <span style={{ paddingLeft: '12px' }}>       
              {address}
            </span>
          )
      ),
      minWidth: '180px',
      omit: omitColumn(columnConfig, 'Address'),
    },
    {
      name: renderInputColumn('Currency', 'Filter by currency', 'currency'),
      cell: ({ currency }) => <span style={{ paddingLeft: '12px' }}>{currency?.symbol}</span>,
      omit: omitColumn(columnConfig, 'Currency'),
      minWidth: '120px',
    },
    {
      name: renderInputColumn('Network', 'Filter by network', 'network'),
      cell: ({ network }) => <span style={{ paddingLeft: '12px' }}>{network ?? '-'}</span>,
      minWidth: '120px',
      omit: omitColumn(columnConfig, 'Network'),
    },
    {
      name: 'In Use',
      minWidth: '120px',
      cell: ({ _id }) => (
        <button
          type="button"
          style={{ minWidth: '90px' }}
          className="btn btn-warning btn-sm me-2"
          onClick={() => showModalContext({
            headerContent: <h3>List of in-use users</h3>, 
            bodyContent: <UsersInUseModal _id={_id} />,
          })}
        >
          View Users
        </button>
      ),
      omit: omitColumn(columnConfig, 'In Use'),
    },
    {
      name: 'Actions',
      minWidth: '450px',
      style: {
        display: 'flex',
        justifyContent: 'flex-start !important',
      },
      cell: ({
        _id, currency, network, isDefault, address,
      }) => (
        isUserCanDeletePspOther || isUserCanEditPspOther ? (
          <div className="action-btn">
            {
              permissionName && permissionName.length > 0 && permissionName.includes('set_default_admin_address')
                ? isDefault 
                  ? (
                    <button
                      type="button"
                      className="btn btn-warning btn-sm me-2"
                      onClick={() => unsetDefault(_id)}
                    >
                      Unset Default
                    </button>
                  )
                  : (
                    <button
                      type="button"
                      className="btn btn-success btn-sm me-2"
                      onClick={() => setDefaultAction(
                        _id,
                        currency?._id,
                        network,
                      )}
                    >
                      Set As Default
                    </button>
                  )
                : null
            }
            {
              permissionName && permissionName.length > 0 && permissionName.includes('edit_admin_address')
                ? (
                  <button
                    type="button"
                    className="btn btn-success btn-sm me-1 p-1"
                    onClick={() => history.push(`/edit-admin-address/${_id}`)}
                  >
                    <FontAwesomeIcon icon={faPencil} />
                  </button>
                )
                : null
            }
            {
              permissionName && permissionName.length > 0 && permissionName.includes('delete_admin_address')
                ? (
                  <button
                    type="button"
                    className="btn btn-danger btn-sm me-1 p-1"
                    onClick={() => deleteAction(_id)}
                  >
                    <FontAwesomeIcon icon={faTrash} />
                  </button>
                )
                : null
            }
            {
              permissionName && permissionName.length && permissionName.includes('edit_admin_address') && address?.startsWith('xpub')
                ? (
                  <button
                    type="button"
                    style={{ fontSize: '16px' }}
                    className="btn btn-warning btn-sm me-2"
                    onClick={() => showModal(_id)}
                  >
                    Generated Wallets
                  </button>
                )
                : null
            }
          </div>
        ) : '-'
      ),
      omit: omitColumn(columnConfig, 'Actions'),
    },
  ];

  return columns;
};
