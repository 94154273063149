import React, { useState, useEffect, useRef } from 'react';
import { faTimes, faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ListIcon from '@mui/icons-material/List';
import CloseIcon from '@mui/icons-material/Close';
import DatePicker, { DateObject } from 'react-multi-date-picker';
import Icon from 'react-multi-date-picker/components/icon';
import 'react-multi-date-picker/styles/backgrounds/bg-dark.css';
import './style.css';
import TimePicker from 'react-multi-date-picker/plugins/time_picker';
import { useClickOutside } from '../../hooks/useClickOutside';

export function ModalFilters({
  data = [], filters, setFilters, tabName, searchParams, usersFilters, setUserFilters, isDateOptions = false,
}) {
  const ModalFilterRef = useRef(null);
  const [showFilter, setShowFilter] = useState(false);
  const [filterText, setFilterText] = useState('');
  const [filtredItems, setFilteredItems] = useState([]);
  const [datePickerValue, setDatePickerValue] = useState(new DateObject());
  const [prevCustomFilterId, setPrevCustomFilterId] = useState();
  const [customListCheck, setCustomListCheck] = useState(false);
  const datePickerRef = useRef();
  const applyBtnDisable = (datePickerValue.length === 2 || !Array.isArray(datePickerValue));
  
  useClickOutside({
    onClickOutside: () => { setShowFilter(false); },
    ref: ModalFilterRef, 
  });

  useEffect(() => {
    if (filters.length === 1 && typeof filters[0] === 'string' && filters[0].includes('||')) {
      const [filtersValue] = filters;
      const listItemMatch = filtredItems.find((el) => el._id === filtersValue);
      setCustomListCheck(listItemMatch === undefined);
      const dateRange = filters[0].split('||');
      if (dateRange.length === 2) {
        const fromDate = new DateObject({ date: new Date(dateRange[0]) });
        const toDate = new DateObject({ date: new Date(dateRange[1]) });
        setDatePickerValue([fromDate, toDate]);
      }
    }
  }, [filters, filtredItems]);


  const handleFilter = () => {
    const filteredResult = [];

    data.forEach((item) => {
      searchParams.params.forEach((param) => {
        const contained = filteredResult.filter((res) => res._id === item._id);
        if (item[param]?.toLowerCase().includes(filterText.toLowerCase()) && !contained.length) filteredResult.push(item);
      });
    });

    if (filteredResult.length) {
      setFilteredItems([...filteredResult]);
    }
  };

  const setOptionName = (item) => {
    let optionName = '';

    searchParams.optionsName.forEach((name) => {
      optionName += ` ${item[name]}`; 
    });

    return optionName;
  };
    
  const handleSelectAll = () => {
    const filters = data.map((item) => item[searchParams.id]);
    setFilters(filters);
    setUserFilters({ ...usersFilters, [tabName.toLowerCase()]: filters });
  };
    
  const handleClearAll = () => {
    setFilters([]);
    setUserFilters({ ...usersFilters, [tabName.toLowerCase()]: [] });
    setDatePickerValue(new DateObject());
    setPrevCustomFilterId();
    setCustomListCheck(false);
  };

  const handleClick = (selectedItem) => {
    if (filters.includes(selectedItem[searchParams.id])) {
      const filtersWithRemoved = filters.filter((item) => item !== selectedItem[searchParams.id]);
      setFilters(filtersWithRemoved);
      setUserFilters({ ...usersFilters, [tabName.toLowerCase()]: filtersWithRemoved });
    } else {
      const filtersWithAdded = [selectedItem[searchParams.id]];
      setFilters(filtersWithAdded);
      setUserFilters({ ...usersFilters, [tabName.toLowerCase()]: filtersWithAdded });
    } 
  };

  const handleSetDateRange = (dateRange) => {
    const resultRange = dateRange.map((item) => new Date(item.year, item.month.index, item.day, item.hour, item.minute).toISOString());
    setDatePickerValue(dateRange);
    const _id = resultRange.join('||');
    setPrevCustomFilterId(_id);
        
    const filtersWithAdded = prevCustomFilterId ? [...filters.filter((item) => item !== prevCustomFilterId), _id] : [_id];
    setFilters(filtersWithAdded);
    setUserFilters({ ...usersFilters, [tabName.toLowerCase()]: filtersWithAdded });
    datePickerRef.current.closeCalendar();
  };

  useEffect(() => {
    setFilteredItems(data);
  }, [data]);

  useEffect(() => {
    handleFilter();
  }, [filterText]);

  return (
    <div className="modal-filter__container" ref={ModalFilterRef}>
      <div
        className="modal-filter__tab-name" 
        onClick={() => setShowFilter(!showFilter)}
      >
        {tabName}
        {!showFilter ? <ListIcon size="small" /> : <CloseIcon size="small" />}
      </div>
      {showFilter ? (
        <div className="leads-modal-filter" style={{ padding: '0 12px' }}>
          <FontAwesomeIcon
            icon={faTimes}
            size="lg"
            color="#6E7F95"
            onClick={() => { setShowFilter(false); }}
          />
          <input
            type="text"
            autoComplete="off"
            placeholder="Search"
            className="leads-search-bar-filter"
            value={filterText}
            onChange={(e) => setFilterText(e.target.value)}
          />
          <button type="button" onClick={() => handleSelectAll()}>Check all</button>
          <button type="button" onClick={() => handleClearAll()}>Uncheck all</button>
          <ul className="modal-filter__list">
            {filtredItems.map((item) => (
              <li
                className="modal-filter__option"
                style={{ backgroundColor: filters.includes(item._id) ? '#061426' : '' }}
                onClick={() => handleClick(item)}
                key={item._id}
              >
                {filters.includes(item[searchParams.id]) ? (
                  <FontAwesomeIcon
                    icon={faCheck}
                    color="#6E7F95"
                  />
                ) : null}
                <div className="modal-filter__option-name">{setOptionName(item)}</div>
              </li>
            ))}
            {isDateOptions 
              && (
              <li 
                className="modal-filter__option"
                key="custom"
                style={{ backgroundColor: customListCheck ? '#061426' : '' }}
              >                         
                <DatePicker
                  className="bg-dark"
                  value={datePickerValue} 
                  ref={datePickerRef}
                  onChange={setDatePickerValue} 
                  range
                  rangeHover
                  format="MM/DD/YYYY HH:mm"
                  plugins={[
                    <TimePicker 
                      hideSeconds 
                    />,
                  ]}
                  render={(value, openCalendar) => (
                    <div
                      onClick={openCalendar}
                    >
                      { customListCheck 
                        ? (
                          <FontAwesomeIcon
                            icon={faCheck}
                            color="#6E7F95"
                            style={{ marginRight: '5px' }}
                          />
                        )
                        : null}
                      Custom
                      <Icon style={{ height: '20px' }} />
                    </div>
                  )}
                >
                  <div>
                    <button type="button" onClick={handleClearAll} className="btn btn-danger btn-sm m-2">
                      Clear
                    </button>
                    <button
                      type="button"
                      onClick={() => handleSetDateRange(datePickerValue)}
                      className="btn btn-success btn-sm me-1 p-1" 
                      style={{ 
                        backgroundColor: applyBtnDisable ? '#3aae51' : '#415244',
                      }}
                      disabled={!applyBtnDisable}
                    >
                      Apply
                    </button>
                  </div>
                </DatePicker>
              </li>
              )}
          </ul>
        </div>
      ) : null}
    </div>
  );
};
