import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { formatDate } from './helper';

export const ClientLoginActivityColumns = (
  clientNameFilter, 
  setClientNameFilter,
  clientIP, 
  setClientIP,
  clientBrowser, 
  setClientBrowser,
) => {
  const permissionName = useSelector((state) => state.crmUser?.currentUserPermissions);

  const clientLoginActivityColumns = [
    {
      name: 'Time',
      selector: (row) => (
        <span style={{ paddingLeft: '12px' }}>
          {formatDate(new Date(row?.createdAt))}
        </span>
      ),
      sortable: true,
      minWidth: '150px',
    },
    {
      name: (
        <div className="d-flex flex-column search-input-des pt-2" style={{ alignItems: 'start' }}>
          Client
          <input
            className="leads-search-bar-filter"
            type="text"
            placeholder="Search"
            autoComplete="off"
            name="clientFullname"
            value={clientNameFilter}
            minLength="3"
            onChange={(e) => setClientNameFilter(e.target.value)}
          />
          {
            (!!clientNameFilter && clientNameFilter.length >= 1 && clientNameFilter.length < 3) 
            && (<span className="datatable-input__error-text clr-red">Must be more than 3 symbols</span>)
          }
        </div>
      ),
      selector: (row) => {
        if (row?.client?._id) {
          return `${row?.client?.firstName} ${row?.client?.lastName}`;
        }
        return '-';
      },
      cell: (row) => {
        if (row?.userId) {
          return permissionName
            && permissionName.length > 0
            && permissionName.includes('edit_user') ? (
              <Link
                to={`/user-detail/${row?.client?._id}`}
                className="text-decoration-none"
              >
                <span
                  className="full-name-text"
                >
                  {`${row?.client?.firstName} ${row?.client?.lastName}`}
                </span>
              </Link>
            ) : (
              <span>{`${row?.client?.firstName} ${row?.client?.lastName}`}</span>
            );
        }
        return '-';
      },
      minWidth: '150px',
      sortable: false,
    },
    {
      name: (
        <div className="d-flex flex-column search-input-des">
          IP
          <input
            className="leads-search-bar-filter"
            type="text"
            placeholder="Search"
            autoComplete="off"
            name="clientIP"
            value={clientIP}
            minLength="3"
            onChange={(e) => setClientIP(e.target.value)}
          />
          {
            (!!clientIP && clientIP.length >= 1 && clientIP.length < 3) 
            && (<span className="datatable-input__error-text clr-red">Must be more than 3 symbols</span>)
          }
        </div>
      ),
      selector: (row) => row?.ip,
      cell: (row) => {
        if (row?.ip) {
          return (
            <span className="full-name-text">
              {row?.ip}
            </span>
          );
        }
        return '-';
      },
      minWidth: '150px',
      sortable: false,
    },
    {
      name: (
        <div className="d-flex flex-column search-input-des pt-2" style={{ alignItems: 'start' }}>
          Browser
          <input
            className="leads-search-bar-filter"
            type="text"
            placeholder="Search"
            autoComplete="off"
            name="clientBrowser"
            value={clientBrowser}
            minLength="3"
            onChange={(e) => setClientBrowser(e.target.value)}
          />
          {
            (!!clientBrowser && clientBrowser.length >= 1 && clientBrowser.length < 3) 
            && (<span className="datatable-input__error-text clr-red">Must be more than 3 symbols</span>)
          }
        </div>
      ),
      selector: (row) => row?.ip,
      cell: (row) => {
        if (row?.browser) {
          return (
            <span className="full-name-text">
              {row?.browser}
            </span>
          );
        }
        return '-';
      },
      sortable: false,
    },
  ];
  return clientLoginActivityColumns;
};
