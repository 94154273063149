import React from 'react';
import Swal from 'sweetalert2';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencil, faTrash } from '@fortawesome/free-solid-svg-icons';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { deleteAdminBankAccount, editAdminBankAccount, setDefaultAdminBankAccount } from '../redux/psp/adminBankAccountActions';
import { omitColumn } from './helper';
import { ErrorSpan } from '../components';
import { UsersInUseModal } from './UsersInUseModal/UsersInUseModal';

export const BankAccountsPspSchema = ({
  permissionName,
  columnConfig,
  tempFilters,
  setTempFilters,
  showModalContext,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { currentUserPermissions } = useSelector((state) => state.crmUser);
  const isUserCanEditPspOther = currentUserPermissions && currentUserPermissions.length > 0 && currentUserPermissions.includes('psp_other_edit');
  const isUserCanDeletePspOther = currentUserPermissions && currentUserPermissions.length > 0 && currentUserPermissions.includes('psp_other_delete');

  const deleteAction = (id) => {
    Swal.fire({
      title: 'Are you sure you want to Delete?',
      html: '',
      showCloseButton: true,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
    }).then(async (result) => {
      if (result.isConfirmed === true) {
        dispatch(deleteAdminBankAccount(id));
      }
    });
  };

  const setDefaultAction = (id) => {
    Swal.fire({
      title: 'Are you sure you want to set it as default bank account?',
      html: '',
      showCloseButton: true,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
    }).then(async (result) => {
      if (result.isConfirmed === true) {
        const data = {
          isDefault: true,
        };
        await dispatch(setDefaultAdminBankAccount(id, data));
      }
    });
  };

  const unsetDefault = (id) => {
    dispatch(editAdminBankAccount(id, { isDefault: false }));
  };

  const renderInputColumn = (fieldName, placeholder, filterKey) => (
    <div className="d-flex flex-column search-input-des pt-2" style={{ alignItems: 'start' }}>
      {fieldName}
      <input
        type="text"
        name={filterKey}
        autoComplete="off"
        value={tempFilters[filterKey] || ''}
        placeholder={placeholder}
        className="email-templates-search-bar-filter"
        onChange={({ target: { value } }) => {
          setTempFilters({
            ...tempFilters,
            [filterKey]: value,
          });
        }}
      />
      <ErrorSpan
        filter={tempFilters[filterKey] || ''}
        message="Must be more than 3 symbols"
      />
    </div>
  );

  const columns = [
    {
      name: renderInputColumn('Name', 'Filter by user name', 'name'),
      cell: ({ name }) => (
        <span style={{ paddingLeft: '12px' }}>
          {name}
        </span>
      ),
      minWidth: '174px',
      omit: omitColumn(columnConfig, 'Name'), 
    },
    {
      name: renderInputColumn('IBAN', 'Filter by IBAN', 'iban'),
      cell: ({ iban }) => iban,
      minWidth: '174px',
      omit: omitColumn(columnConfig, 'IBAN'), 
    },
    {
      name: renderInputColumn('Account Number', 'Filter by Account Number', 'accountNumber'),
      cell: ({ accountNumber }) => accountNumber,
      minWidth: '174px',
      omit: omitColumn(columnConfig, 'Account Number'), 
    },
    {
      name: renderInputColumn('Bank Address', 'Filter by Bank Address', 'bankAddress'),
      cell: ({ bankAddress }) => bankAddress,
      minWidth: '174px',
      omit: omitColumn(columnConfig, 'Bank Address'), 
    },
    {
      name: renderInputColumn('Swift Code', 'Filter by Swift Code', 'swiftCode'),
      cell: ({ swiftCode }) => swiftCode,
      minWidth: '174px',
      omit: omitColumn(columnConfig, 'Swift Code'), 
    },
    {
      name: 'In Use',
      cell: ({ _id }) => (
        <button
          type="button"
          style={{ minWidth: '90px' }}
          className="btn btn-warning btn-sm me-2"
          onClick={() => showModalContext({
            headerContent: <h3>List of in-use users</h3>, 
            bodyContent: <UsersInUseModal _id={_id} />,
          })}
        >
          View Users
        </button>
      ),
      minWidth: '120px',
      omit: omitColumn(columnConfig, 'In Use'),
    },
    {
      name: 'Actions',
      minWidth: '290px',
      style: {
        display: 'flex',
        justifyContent: 'flex-start !important',
      },
      cell: ({ _id, isDefault }) => (
        isUserCanDeletePspOther || isUserCanEditPspOther ? (
          <div className="action-btn">
            {
              permissionName && permissionName.length > 0 && permissionName.includes('set_default_admin_bank_account')
                ? isDefault 
                  ? (
                    <button
                      type="button"
                      className="btn btn-warning btn-sm me-2"
                      onClick={() => unsetDefault(_id)}
                    >
                      Unset Default
                    </button>
                  ) : (
                    <button
                      type="button"
                      className="btn btn-success btn-sm me-2"
                      onClick={() => setDefaultAction(_id)}
                    >
                      Set As Default
                    </button>
                  )
                : null
            }
            {
              permissionName && permissionName.length > 0 && permissionName.includes('edit_admin_bank_account')
                ? (
                  <button
                    type="button"
                    className="btn btn-success btn-sm me-1 p-1"
                    onClick={() => history.push(`/edit-bank-account/${_id}`)}
                  >
                    <FontAwesomeIcon icon={faPencil} />
                  </button>
                )
                : null
            }
            {
              permissionName && permissionName.length > 0 && permissionName.includes('delete_admin_bank_account')
                ? (
                  <button
                    type="button"
                    className="btn btn-danger btn-sm me-1 p-1"
                    onClick={() => deleteAction(_id)}
                  >
                    <FontAwesomeIcon icon={faTrash} />
                  </button>
                )
                : null
            }
          </div>
        ) : '-'
      ),
      omit: omitColumn(columnConfig, 'Actions'), 
    },
  ];

  return columns;
};
