import React from 'react';
import { Link } from 'react-router-dom';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClone, faExternalLink, faTrash } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import { toFixed } from '../../helpers/utils';

import { RangeModalDebounceFilter } from '../../pages/Transactions/modalFilters';
import { ModalFilters } from '../../components';
import {
  omitColumn, formatDate, dateOptions, prettyCutOutputByCellWidth, valueOutputCutLength,  
} from '../helper';
import { TooltipComponent } from '../../components/TooltipComponent/TooltipComponent';

import './style.css';

export const CfdAccountsSchema = (
  columnConfig,
  amountRange,
  setAmountRange,
  cfdQuery,
  setCfdQuery,
  currencies,
  assetFilter,
  setAssetFilter,
  creditRange,
  setCreditRange,
  permissionName,
  balanceRange,
  setBalanceRange,
  setDebounceAmountRange,
  customIdFilter, 
  setCustomIdFilter,
  fullNameFilter, 
  setFullNameFilter,
  handleDeleteCfdAccounts,
  setInputFieldError,
  inputFieldError,
  accountTypes,
  crmUsers,
  assignedToFilter,
  setAssignedToFilter,
  salesStatuses,
  statusFilter,
  setStatusFilter,
  createdAtFilter,
  setCreatedAtFilter,
  orderData,
) => {
  const isUserCanSeeCfdAccounts = permissionName.includes('cfd_accounts');
  const copyReferral = () => {
    toast.success('Successfully copied!', {
      autoClose: 1000,
    });
  };

  const handleValidationInputs = ({ target: { value } }, nameField) => {
    if (value.length < 3 && value !== '') setInputFieldError((prev) => ({ ...prev, errorMessage: 'Must be more than 3 symbols', [nameField]: true }));
    else setInputFieldError((prev) => ({ ...prev, errorMessage: '', [nameField]: false }));
    if (nameField === 'fullNameIsNotValid') setFullNameFilter(value);
  };

  const calcMargin = (orders) => {
    if (!orders.length) return 0;
    let margin = 0;

    // eslint-disable-next-line no-restricted-syntax
    for (const order of orders) {
      if (Object.keys(orderData).includes(order._id)) {
        margin += Number(orderData[order._id].margin);
      }
    }

    return toFixed(margin, 2);
  };

  const calcPnL = (orders) => {
    if (!orders.length) return 0;
    let pnl = 0;

    // eslint-disable-next-line no-restricted-syntax
    for (const order of orders) {
      if (Object.keys(orderData).includes(order._id)) {
        pnl += Number(orderData[order._id].pnl);
      }
    }

    return toFixed(pnl, 2);
  };

  const styles = {
    positive: { color: 'green' },
    negative: { color: 'red' },
    default: {},
  };
  
  const columns = [
    {
      name: (
        <div className="cfdAccount_idWrapper">
          <p className="cfdAccount_idWrapper_text">ID</p>
          <input 
            type="text"
            placeholder="Search"
            autoComplete="off"
            className="cfdAccount_input"
            style={inputFieldError.transactionIdIsNotValid ? { border: '1px solid red' } : { border: 'none' }}
            name="id"
            value={customIdFilter || ''}
            onChange={(e) => setCustomIdFilter(e.target.value)}
          />
          <span className="cfdAccount_errorInputWrapper" />
        </div>),
      minWidth: '150px',
      omit: omitColumn(columnConfig, 'ID'), 
      cell: ({ customId }) => (
        customId.length >= valueOutputCutLength.id
          ? (
            <TooltipComponent
              title={customId}
              // eslint-disable-next-line react/no-children-prop
              children={(
                <CopyToClipboard text={customId}>
                  <span className="cfdSccounts-row_Id">
                    <FontAwesomeIcon icon={faClone} onClick={() => copyReferral()} className="me-2" />
                    { customId }
                  </span>
                </CopyToClipboard>
              )}
            />
          )
          : (
            <CopyToClipboard text={customId}>
              <span className="cfdSccounts-row_Id">
                <FontAwesomeIcon icon={faClone} onClick={() => copyReferral()} className="me-2" />
                { customId }
              </span>
            </CopyToClipboard>
          )
      ),
    },
    {
      name: (
        <div className="cfdAccount_idWrapper">
          <p className="cfdAccount_idWrapper_text">Client</p>
          <input 
            type="text"
            placeholder="Search"
            autoComplete="off"
            name="client"
            className="cfdAccount_input"
            value={fullNameFilter || ''}
            onChange={(e) => handleValidationInputs(e, 'fullNameIsNotValid')}
          />
          <span className="cfdAccount_errorInputWrapper">
            {inputFieldError.fullNameIsNotValid && (
              <p className="cfdAccount_errorInput">
                {inputFieldError.errorMessage}
              </p>
            )}
          </span> 
        </div>),
      minWidth: '180px',
      omit: omitColumn(columnConfig, 'Client'), 
      cell: ({ user, userId }) => { 
        if (user?.firstName && user?.lastName) {
          const userFullName = `${user?.firstName} ${user?.lastName}`; 

          return (
            <div className="cfdSccounts-row_ClientWrapper">
              { 
               userFullName.length >= valueOutputCutLength.clientWithdrawal
                 ? (
                   <TooltipComponent
                     title={userFullName}
                   // eslint-disable-next-line react/no-children-prop
                     children={(
                       <Link to={`/user-detail/${userId}`} target="blank" className="text-white">
                         <FontAwesomeIcon icon={faExternalLink} size="xs" />
                         <span 
                           className="cfdSccounts-row_fullName"
                           style={{ whiteSpace: 'nowrap' }}
                         >
                           { prettyCutOutputByCellWidth(userFullName, 'clientWithdrawal') }
                         </span>
                       </Link>
                   )}
                   />
                 )
                 : (
                   <Link to={`/user-detail/${userId}`} target="blank" className="text-white">
                     <FontAwesomeIcon icon={faExternalLink} size="xs" />
                     <span 
                       className="cfdSccounts-row_fullName"
                       style={{ whiteSpace: 'nowrap' }}
                     >
                       {userFullName}
                     </span>
                   </Link>
                 )
            }
            </div>
          );
        }
        
        return <span className="cfdSccounts-row_fullName">-</span>;
      },
    },
    {
      name: (
        <ModalFilters 
          data={crmUsers} 
          filters={assignedToFilter} 
          setFilters={setAssignedToFilter} 
          tabName="Assigned To" 
          searchParams={{ id: '_id', params: ['firstName', 'lastName'], optionsName: ['firstName', 'lastName'] }}
          usersFilters={cfdQuery}
          setUserFilters={setCfdQuery}
        />
      ),
      omit: omitColumn(columnConfig, 'Assigned To'), 
      minWidth: '180px',
      cell: ({ user }) => {
        if (user?.manager?.firstName && user?.manager?.lastName) {
          const fullNameManager = `${user.manager.firstName} ${user.manager.lastName}`;
          return (
            <div className="cfdSccounts-row_ClientWrapper">
              { fullNameManager.length >= valueOutputCutLength.assignedTo
                ? (
                  <TooltipComponent
                    title={fullNameManager}
                    // eslint-disable-next-line react/no-children-prop
                    children={(
                      <Link to={`/edit-admin/${user.manager._id}`} target="blank" className="text-white">
                        <FontAwesomeIcon icon={faExternalLink} size="xs" />
                        <span 
                          className="cfdSccounts-row_fullName"
                        >
                          { prettyCutOutputByCellWidth(fullNameManager, 'assignedTo') }
                        </span>
                      </Link>
                    )}
                  />
                )
                : (
                  <Link to={`/edit-admin/${user.manager._id}`} target="blank" className="text-white">
                    <FontAwesomeIcon icon={faExternalLink} size="xs" />
                    <span 
                      className="cfdSccounts-row_fullName"
                    >
                      {fullNameManager}
                    </span>
                  </Link>
                )}
            </div>
          );
        }
          
        return <span className="cfdSccounts-row_fullName">-</span>;
      },
    },
    {
      name: (
        <ModalFilters 
          data={salesStatuses} 
          filters={statusFilter} 
          setFilters={setStatusFilter} 
          tabName="Status" 
          searchParams={{ id: '_id', params: ['name'], optionsName: ['name'] }}
          usersFilters={cfdQuery}
          setUserFilters={setCfdQuery}
        />
      ),
      omit: omitColumn(columnConfig, 'Status'), 
      minWidth: '140px',
      cell: ({ user }) => {
        if (user?.status && user?.status?.name) {
          const userStatusName = user?.status?.name;
          return (
            userStatusName.length >= valueOutputCutLength.managerStatus
              ? (
                <TooltipComponent
                  title={userStatusName}
                // eslint-disable-next-line react/no-children-prop
                  children={(
                    <span 
                      className="cfdSccounts-row_fullName"
                    >
                      { prettyCutOutputByCellWidth(userStatusName, 'managerStatus') }
                    </span>
                )}
                />
              )
              : (
                <span 
                  className="cfdSccounts-row_fullName"
                >
                  {userStatusName}
                </span>
              )
          );
        }
        return <span className="cfdSccounts-row_fullName">-</span>;
      },
    },
    {
      name: (
        <ModalFilters 
          data={accountTypes} 
          filters={assetFilter} 
          setFilters={setAssetFilter} 
          tabName="Type" 
          searchParams={{ id: '_id', params: ['name'], optionsName: ['name'] }}
          usersFilters={cfdQuery}
          setUserFilters={setCfdQuery}
        />
      ),
      omit: omitColumn(columnConfig, 'Type'), 
      minWidth: '90px',
      cell: ({ type }) => (type?.name),
    },
    {
      name: (
        <RangeModalDebounceFilter
          columnName="Total Amount" 
          value={amountRange ?? [0, 1000000]} 
          setValue={setAmountRange} 
          setFilters={setDebounceAmountRange} 
          filters={{}} 
          filedName="amountRange"
          debounceAmountRange
        />
      ),
      minWidth: '160px',
      omit: omitColumn(columnConfig, 'Amount'), 
      cell: ({ total }) => (
        total?.toFixed(2).toString().length >= valueOutputCutLength.netDeposits
          ? (
            <TooltipComponent
              title={total?.toFixed(2).toString()}
                  // eslint-disable-next-line react/no-children-prop
              children={(
                <span 
                  className="cfdSccounts-row_fullName"
                >
                  { prettyCutOutputByCellWidth(total?.toFixed(2).toString(), 'netDeposits') }
                </span>
                )}
            />
          )
          : (
            <span 
              className="cfdSccounts-row_fullName"
            >
              { total?.toFixed(2) }
            </span>
          )
      ),
    },
    {
      name: (
        <RangeModalDebounceFilter 
          columnName="Balance" 
          value={balanceRange ?? [0, 1000000]} 
          setValue={setBalanceRange} 
          setFilters={setDebounceAmountRange} 
          filters={{}} 
          filedName="balanceRange"
          debounceAmountRange
        />
      ),
      minWidth: '110px',
      omit: omitColumn(columnConfig, 'Balance'), 
      cell: ({ balance }) => (
        balance.toFixed(2).toString()?.length >= valueOutputCutLength.balance 
          ? (
            <TooltipComponent 
              title={balance.toFixed(2).toString()}
               // eslint-disable-next-line react/no-children-prop
              children={(
                <span style={{ cursor: 'pointer' }}>
                  {prettyCutOutputByCellWidth(balance.toFixed(2).toString(), 'balance')}
                </span>
              )}
            />
          )
          : (
            <span>
              {balance.toFixed(2).toString()}
            </span>
          )
      ),
    },
    {
      name: (
        <ModalFilters 
          data={currencies} 
          filters={assetFilter} 
          setFilters={setAssetFilter} 
          tabName="Currency" 
          searchParams={{ id: '_id', params: ['name', '_id', 'symbol'], optionsName: ['symbol'] }}
          usersFilters={cfdQuery}
          setUserFilters={setCfdQuery}
        />
      ),
      omit: omitColumn(columnConfig, 'Currency'), 
      minWidth: '120px',
      cell: ({ cfdAccountCurrency, isSaving, currency }) => (!isSaving ? cfdAccountCurrency?.symbol : currency?.symbol),
    },
    {
      name: ( 
        <RangeModalDebounceFilter
          columnName="Credit" 
          value={creditRange ?? [0, 1000000]} 
          setValue={setCreditRange} 
          setFilters={setCfdQuery} 
          filters={cfdQuery}
          filedName="creditRange"
        />
      ),
      minWidth: '140px',
      omit: omitColumn(columnConfig, 'Credit'), 
      cell: ({ credit, cfdAccountCurrency, isSaving }) => {
        if (!isSaving) {
          return (
            credit?.toFixed(2).toString()?.length >= valueOutputCutLength.credit 
              ? (
                <TooltipComponent 
                  title={credit?.toFixed(2).toString()}
                 // eslint-disable-next-line react/no-children-prop
                  children={(
                    <span style={{ cursor: 'pointer' }}>
                      {prettyCutOutputByCellWidth(`${credit?.toFixed(2)} ${cfdAccountCurrency?.symbol}`, 'credit')}
                    </span>
                )}
                />
              )
              : (
                <span>
                  {`${credit?.toFixed(2)} ${cfdAccountCurrency?.symbol}`}
                </span>
              )
          );
        }
        return <span className="cfdSccounts-row_fullName">-</span>;
      },
    },
    {
      name: 'Leverage',
      minWidth: '120px',
      selector: ({ leverage }) => (leverage || '-'), 
      omit: omitColumn(columnConfig, 'Leverage'), 
      sortable: true,
    },
    {
      name: 'Lot Step',
      minWidth: '110px',
      selector: ({ lotStep }) => (lotStep || '-'), 
      omit: omitColumn(columnConfig, 'Lot Step'), 
      sortable: true,
    },
    {
      name: 'Lot Size',
      minWidth: '110px',
      selector: ({ lotSize }) => (lotSize || '-'),
      omit: omitColumn(columnConfig, 'Lot Size'), 
      sortable: true,
    },
    {
      name: 'Commission%',
      minWidth: '140px',
      selector: ({ commission }) => (commission || '-'), 
      omit: omitColumn(columnConfig, 'Commission'), 
      sortable: true,
    },
    {
      name: 'Swap Long%',
      minWidth: '140px',
      selector: ({ swapLong }) => (swapLong || '-'), 
      omit: omitColumn(columnConfig, 'Swap Long'), 
      sortable: true,
    },
    {
      name: 'Swap Short%',
      minWidth: '145px',
      selector: ({ swapShort }) => (swapShort || '-'), 
      omit: omitColumn(columnConfig, 'Swap Short'), 
      sortable: true,
    },
    {
      name: 'Margin',
      minWidth: '110px',
      selector: ({ orders }) => {
        const margin = calcMargin(orders);
        const spanStyle = margin !== 0 ? (margin > 0 ? styles.positive : styles.negative) : styles.default;
        return (
          margin?.toString()?.length >= valueOutputCutLength.margin 
            ? (
              <TooltipComponent 
                title={margin?.toString()}
                // eslint-disable-next-line react/no-children-prop
                children={(
                  <span style={spanStyle}>
                    {prettyCutOutputByCellWidth(margin, 'margin')}
                  </span>
            )}
              />
            )
            : (
              <span style={spanStyle}>
                {margin}
              </span>
            )
        );
      }, 
      omit: omitColumn(columnConfig, 'Margin'), 
    },
    {
      name: 'Margin Level',
      minWidth: '130px',
      selector: ({ orders, balance, credit }) => {
        const margin = calcMargin(orders);
        const pnl = calcPnL(orders);
        const equity = Number(balance) + pnl + Number(credit);
        const marginLevel = margin !== 0 ? (equity / margin) * 100 : 0;
        const spanStyle = marginLevel !== 0 ? (marginLevel > 0 ? styles.positive : styles.negative) : styles.default;
        return (
          toFixed(marginLevel, 2).toString().length >= valueOutputCutLength.margin 
            ? (
              <TooltipComponent 
                title={toFixed(marginLevel, 2).toString()}
              // eslint-disable-next-line react/no-children-prop
                children={(
                  <span style={spanStyle}>
                    {`${prettyCutOutputByCellWidth(toFixed(marginLevel, 2).toString(), 'margin')} %`}
                  </span>
          )}
              />
            )
            : (
              <span style={spanStyle}>
                {`${toFixed(marginLevel, 2).toString()} %`}
              </span>
            )
        );
      }, 
      omit: omitColumn(columnConfig, 'Margin Level'), 
    },
    {
      name: 'Free Margin',
      minWidth: '130px',
      selector: ({ orders, balance, credit }) => {
        const margin = calcMargin(orders);
        const pnl = calcPnL(orders);
        const equity = Number(balance) + pnl + Number(credit);
        const freeMargin = equity - margin;
        
        const spanStyle = freeMargin !== 0 ? (freeMargin > 0 ? styles.positive : styles.negative) : styles.default;
        return (
          toFixed(freeMargin, 2).toString().length >= valueOutputCutLength.margin 
            ? (
              <TooltipComponent 
                title={toFixed(freeMargin, 2).toString()}
                // eslint-disable-next-line react/no-children-prop
                children={(
                  <span style={spanStyle}>
                    {prettyCutOutputByCellWidth(toFixed(freeMargin, 2).toString(), 'margin')}
                  </span>
                )}
              />
            )
            : (
              <span style={spanStyle}>
                {toFixed(freeMargin, 2)}
              </span>
            )
        );
      }, 
      omit: omitColumn(columnConfig, 'Free Margin'), 
    },
    {
      name: (
        <ModalFilters 
          onClick={(e) => e.stopPropagation()}
          data={dateOptions} 
          filters={createdAtFilter} 
          setFilters={setCreatedAtFilter} 
          tabName="Created" 
          searchParams={{ id: '_id', params: ['name'], optionsName: ['name'] }}
          usersFilters={cfdQuery}
          setUserFilters={setCfdQuery}
          isDateOptions
        />
      ),
      minWidth: '154px',
      omit: omitColumn(columnConfig, 'Created'),
      selector: ({ createdAt }) => (
        <span>{formatDate(new Date(createdAt))}</span>
      ),
    },
    {
      name: 'Action(s)',
      minWidth: '80px',
      omit: omitColumn(columnConfig, 'Actions'), 
      cell: (row) => (isUserCanSeeCfdAccounts ? (
        <button
          type="button"
          className="btn btn-danger btn-sm me-1 p-1"
          onClick={() => handleDeleteCfdAccounts(row)}
        >
          <FontAwesomeIcon icon={faTrash} className="header-icon" />
        </button>
      ) : (<span>-</span>)),
    },
  ];
  return columns;
};
