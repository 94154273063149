import React, { useContext, useState } from 'react';
import { faPencil } from '@fortawesome/free-solid-svg-icons';
import { BsTrashFill } from 'react-icons/bs';
import Switch from '@mui/material/Switch';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import { Link } from '@mui/material';
import { omitColumn } from './helper';
import { ModalFilters } from '../components';
import { ModalContext } from '../context';
import { deleteVoipById, editVoip } from '../redux/voip/voipActions';
import { EditVoipModal } from '../pages/Voip/modals/editVoipModal';

export const voipSchema = ({
  columnConfig,
  fullNameFilter,
  setFullNameFilter,
  providerFilter,
  setProviderFilter,
  usersFilters,
  setUserFilters,
  extensionFilter,
  setExtensionFilter,
  brandFilter,
  setBrandFilter,
  statusFilter,
  setStatusFilter,
}) => {
  const dispatch = useDispatch();
  const { showModal } = useContext(ModalContext);

  const voipProviders = useSelector((state) => state.voip.voipProviders);
  const brands = useSelector((state) => state.brands);
  const uId = useSelector((state) => state.crmUser.crmUserInfo._id);
  const { currentUserPermissions } = useSelector((state) => state.crmUser);
  const isUserCanEditVoip = currentUserPermissions && currentUserPermissions.length > 0 && currentUserPermissions.includes('voip_edit');
  const isUserCanDeleteVoip = currentUserPermissions && currentUserPermissions.length > 0 && currentUserPermissions.includes('voip_delete');

  const [inputFieldError, setInputFieldError] = useState({
    fullNameIsNotValid: false,
    errorMessage: '',
  });

  const handleValidationInputs = ({ target: { value } }, nameField) => {
    if (value.length < 3 && value.length > 0) setInputFieldError((prev) => ({ ...prev, errorMessage: 'Must be more than 3 symbols', [nameField]: true }));
    else setInputFieldError((prev) => ({ ...prev, errorMessage: '', [nameField]: false }));
    if (nameField === 'fullNameIsNotValid') setFullNameFilter(value);
  };


  const handleChangeEnableStatus = (row) => {
    const { _id, isEnabled } = row;

    dispatch(editVoip(_id, uId, { ...row, isEnabled: !isEnabled }));
  };

  const handleEditVoip = (row) => {
    showModal({
      bodyContent: <EditVoipModal row={row} />,
      headerContent: <h3>Edit VoIP</h3>, 
    });
  };

  const handleDeleteVoip = (id) => {
    Swal.fire({
      title: 'Are you sure you want to Delete?',
      html: '',
      showCloseButton: true,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
    }).then((result) => {
      if (result.isConfirmed === true) {
        dispatch(deleteVoipById(id));
      }
    });
  };

  const voipColumns = [
    {
      name: (
        <div className="d-flex flex-column search-input-des pt-2" style={{ alignItems: 'start' }}>
          Agent
          <input
            className="leads-search-bar-filter"
            style={inputFieldError.fullNameIsNotValid ? { border: '1px solid red' } : { border: 'none' }}
            type="text"
            placeholder="Search"
            name="fullname"
            autoComplete="off"
            value={fullNameFilter ?? ''}
            onChange={(e) => handleValidationInputs(e, 'fullNameIsNotValid')}
          />
          <span className="cfdAccount_errorInputWrapper">
            {inputFieldError.fullNameIsNotValid && (
              <p className="cfdAccount_errorInput">
                {inputFieldError.errorMessage}
              </p>
            )}
          </span>
        </div>
      ),
      minWidth: '200px',
      cell: ({ agent }) => {
        const agentFullName = `${agent?.firstName} ${agent?.lastName}`;
        return (
          <span style={{ paddingLeft: '12px', whiteSpace: 'nowrap' }}>
            {agent ? agentFullName : '-'}
          </span>
        );
      },
      omit: omitColumn(columnConfig, 'Agent'),
    },
    {
      name: (
        <ModalFilters 
          data={voipProviders} 
          filters={providerFilter} 
          setFilters={setProviderFilter} 
          tabName="Provider" 
          searchParams={{ id: '_id', params: ['name'], optionsName: ['name'] }}
          usersFilters={usersFilters}
          setUserFilters={setUserFilters}
        />
      ),
      minWidth: '100px',
      cell: ({ provider: { name } }) => name,
      omit: omitColumn(columnConfig, 'Provider'),
    },
    {
      name: (
        <div className="d-flex flex-column search-input-des pt-2" style={{ alignItems: 'start' }}>
          Extension
          <input
            className="leads-search-bar-filter"
            style={inputFieldError.extension ? { border: '1px solid red' } : { border: 'none' }}
            type="text"
            placeholder="Search"
            name="fullname"
            autoComplete="off"
            value={extensionFilter ?? ''}
            onChange={({ target: { value } }) => setExtensionFilter(value)}
          />
          <span className="cfdAccount_errorInputWrapper">
            {inputFieldError.extension && (
            <p className="cfdAccount_errorInput">
              {inputFieldError.errorMessage}
            </p>
            )}
          </span>
        </div>
      ),
      minWidth: '100px',
      cell: ({ extension }) => extension,
      omit: omitColumn(columnConfig, 'Extension'),
    },
    {
      name: (
        <ModalFilters 
          data={brands} 
          filters={brandFilter} 
          setFilters={setBrandFilter} 
          tabName="Brand Name" 
          searchParams={{ id: '_id', params: ['name'], optionsName: ['name'] }}
          usersFilters={usersFilters}
          setUserFilters={setUserFilters}
        />
      ),
      minWidth: '140px',
      cell: ({ brand: { name } }) => name,
      omit: omitColumn(columnConfig, 'Brand Name'),
    },
    {
      name: (
        <ModalFilters
          data={[{ name: 'Enabled', status: true }, { name: 'Disabled', status: false }]}
          filters={statusFilter}
          setFilters={setStatusFilter}
          tabName="Status" 
          searchParams={{ id: 'status', params: ['name'], optionsName: ['name'] }}
          usersFilters={usersFilters}
          setUserFilters={setUserFilters}
        />
      ),
      minWidth: '100px',
      cell: ({ isEnabled }) => (isEnabled ? 'Enabled' : 'Disabled'),
      omit: omitColumn(columnConfig, 'Status'),
    },
    {
      name: 'Actions',
      cell: (row) => (
        <div>
          {isUserCanEditVoip && (
            <Switch checked={row.isEnabled} color="success" onChange={() => handleChangeEnableStatus(row)} />
          )}
          {isUserCanEditVoip && (
            <button type="button" className="btn btn-success btn-sm me-1 p-1" onClick={() => handleEditVoip(row)}>
              <FontAwesomeIcon icon={faPencil} />
            </button>
          )}
          {isUserCanDeleteVoip && (
            <button type="button" className="btn btn-danger btn-sm me-1 p-1" onClick={() => handleDeleteVoip(row._id)}>
              <BsTrashFill role="button" size={18} />
            </button>
          )}
        </div>
      ),
      minWidth: '250px',
      omit: omitColumn(columnConfig, 'Actions'),
    },
  ];

  return voipColumns;
};
