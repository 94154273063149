import React from 'react';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';


const TooltipCustom = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    width: '100%',
    maxWidth: '100%',
    borderRadius: '4px',
    height: '24px',
    display: 'flex',
    flexDirection: 'row',
    padding: '4px 8px',
    backgroundColor: '#434353',
    color: '#F0F0F7',
    fontFamily: 'Play',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '16px', 
    letterSpacing: '0.5px',
    textAlign: 'start',
  },
}));

export function TooltipComponent({
  title,
  children,
}) {
  return (
    <TooltipCustom
      title={title}
    >
      { children }
    </TooltipCustom>
  );
}

